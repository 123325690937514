<template>
  <base-popup
    title="Выбор урока/материала"
    :icon="UtilGetIcon('view-knowledgebase')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    v-bind="$attrs"
    :buttonSaveText="'Копировать'"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-container>
      <v-row>
        <v-col>
          <base-panel title="Курсы">
            <base-search slot="action" v-model="search[0]" />
            <base-table
              :items="courses"
              :search-text="search[0]"
              :headers="courseHeaders"
              hide-default-footer
              notShowSelect
              notShowSettings
              notFilter
              @click:row="selectCourse = $event"
            >
              <template slot="item.Name" slot-scope="{ item }">
                {{ item.Name }}
                <b v-if="item.selectLessons && item.selectLessons.length">
                  ({{ item.selectLessons.length }})
                </b>
              </template>
            </base-table>
          </base-panel>
        </v-col>
        <v-col>
          <base-panel title="Уроки">
            <base-search slot="action" v-model="search[1]" />
            <base-table
              v-model="selectLessons"
              :items="lessons"
              :search-text="search[1]"
              :headers="positionHeaders"
              hide-default-footer
              notShowSettings
              notFilter
              @click:row="clickLesson"
            >
              <template slot="item.Name" slot-scope="{ item }">
                {{ item.Name }}
                <b v-if="item.selectRecords && item.selectRecords.length">
                  ({{ item.selectRecords.length }})
                </b>
              </template>
              <template v-slot:body.append="{ headers }">
                <tr
                  v-if="
                    (selectCourse === null ||
                      (selectCourse !== null && lessons.length === 0)) &&
                    !lessonsLoading
                  "
                >
                  <td :colspan="headers.length" class="font-weight-bold">
                    {{
                      selectCourse === null
                        ? "Выберите курс!"
                        : "В курсе нет уроков!"
                    }}
                  </td>
                </tr>
              </template>
            </base-table>
          </base-panel>
        </v-col>
        <v-col>
          <base-panel title="Материалы">
            <base-search slot="action" v-model="search[2]" />
            <base-table
              v-model="selectRecords"
              :search-text="search[2]"
              :items="records"
              :headers="employeeHeaders"
              hide-default-footer
              notShowSettings
              notFilter
            >
              <template v-slot:body.append="{ headers }">
                <tr
                  v-if="
                    currentLesson === null ||
                    (currentLesson !== null && records.length === 0)
                  "
                >
                  <td :colspan="headers.length" class="font-weight-bold">
                    {{
                      currentLesson === null
                        ? "Выберите урок!"
                        : "В уроке нет материалов!"
                    }}
                  </td>
                </tr>
              </template>
            </base-table>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
  </base-popup>
</template>

<script>
import BasePopup from "@/layouts/BasePopup.vue";

import BasePanel from "@/layouts/BasePanel.vue";
import BaseTable from "@/components/base/BaseTable.vue";
import BaseSearch from "@/components/base/BaseSearch.vue";
import KnowledgeBaseService from "../../../services/KnowledgeBaseService";

export default {
  components: { BasePopup, BasePanel, BaseTable, BaseSearch },
  props: {
    value: { type: Boolean, default: false },
    width: String,
    height: String,
  },
  data() {
    return {
      search: ["", "", ""],
      courses: [],
      coursesDefault: [],
      lessons: [],
      records: [],
      selectCourse: null,
      selectLessons: null,
      selectRecords: null,
      currentLesson: null,
      lessonsLoading: false,

      courseHeaders: [{ text: "Курсы", value: "Name" }],
      positionHeaders: [{ text: "Уроки", value: "Name" }],
      employeeHeaders: [
        {
          text: "Материалы",
          value: "Name",
        },
      ],
      dialog: false,
    };
  },
  computed: {},
  watch: {
    value(val) {
      if (val !== this.dialog) this.dialog = val;
    },
    async dialog(val) {
      if (val) {
        if (this.courses.length) {
          this.courses = (
            await KnowledgeBaseService.course.get({ full: false, fields: 0 })
          ).data.filter((e) => e.Id !== this.$route.params.objectId);
        }
        this.selectCourse = null;
        this.selectLessons = null;
        this.selectRecords = null;
        this.currentLesson = null;

        this.lessons = [];
        this.records = [];
        this.lessonsLoading = false;
      }
      if (val !== this.value) this.$emit("input", val);
    },

    async selectCourse(val) {
      if (this.selectCourse !== null) this.lessonsLoading = true;
      this.currentLesson = null;
      this.records = [];

      if (!val.Lessons?.length) {
        this.lessons = (await KnowledgeBaseService.lesson.get(val.Id)).data;
        val.Lessons = this.lessons;
      } else {
        this.lessons = val.Lessons;
      }

      if (!val.selectLessons) {
        val.selectLessons = [];
        this.selectLessons = [];
      } else {
        this.selectLessons = val.selectLessons;
      }
      this.lessonsLoading = false;
    },
    async selectLessons(val) {
      if (!this.selectCourse) return;
      this.selectCourse.selectLessons = val;
    },
    async selectRecords(val) {
      if (!this.currentLesson) return;
      this.currentLesson.selectRecords = val;

      /* if (
        val.length &&
        !this.selectCourse.selectLessons.find(
          (e) => e.Id === this.currentLesson.Id
        )
      ) {
        this.selectCourse.selectLessons.push(this.currentLesson);
      } */
    },
  },
  async mounted() {
    this.courses = (
      await KnowledgeBaseService.course.get({ fields: 0 })
    ).data.filter((e) => e.Id !== this.$route.params.objectId);
    this.coursesDefault = this._.cloneDeep(this.courses);
  },
  methods: {
    save() {
      let tmp = [];
      let records = [];

      for (let i = 0; i < this.courses.length; i++) {
        for (let j = 0; j < this.courses[i]?.Lessons?.length; j++) {
          const lesson = this.courses[i]?.Lessons[j];
          if (
            this.courses[i]?.selectLessons &&
            this.courses[i].selectLessons.find((e) => e.Id === lesson.Id)
          )
            continue;
          if (lesson.selectRecords)
            records = [...records, ...lesson.selectRecords];
        }
      }

      for (let i = 0; i < this.courses.length; i++) {
        if (this.courses[i]?.selectLessons?.length) {
          const t = this.courses[i];
          const lessons = t.selectLessons;
          for (let j = 0; j < lessons.length; j++) {
            if (lessons[j].Id === -1) {
              lessons[j].Id = -Math.round(Math.random() * 100000);
            }
            if (lessons[j]?.selectRecords && lessons[j]?.selectRecords.length)
              lessons[j].Records = lessons[j].selectRecords;
            lessons[j].selectRecords = [];
          }
          tmp = [...tmp, ...lessons];
        }
      }

      this.$emit("save", { lessons: tmp, records });

      this.dialog = false;
      this.selectLessons = [];
      this.selectRecords = [];
    },
    clickLesson(e) {
      this.records = e.Records;
      this.currentLesson = e;

      if (!this.currentLesson.selectRecords) {
        this.currentLesson.selectRecords = [];
        this.selectRecords = [];
      } else {
        this.selectRecords = this.currentLesson.selectRecords;
      }
    },
  },
};
</script>

<style></style>
