<template>
  <base-tree-list-view
    :dataSource="value"
    :headers="computedHeaders"
    isNested
    header-hidden
    hide-default-footer
    notShowSelect
    :exclude-settings-actions="['group']"
    :show-add-in-table="!readonly ? addMenu : false"
    show-expand
    :show-actions="!isEmployee"
    expand-icon="mdi-comment-arrow-right"
    :groupOpenType="'showFirst'"
    options="Children"
    :show-expanded-tree="false"
    :single-expand="false"
    @customHandle="customHandle"
    id="knowledge-base__records"
    fixedWidthActions
    @customAdd="customAdd"
    dragable
    notShowSettings
    not-filter
  >
    <template slot="item.Type" slot-scope="{ item }">
      <m-icon
        v-if="item.Type === 0"
        class="pl"
        :icon="UtilGetIcon('view-TestEdit')"
      ></m-icon>
      <m-icon
        v-if="item.Type === 1"
        class="pl"
        :icon="UtilGetIcon('view-MaterialEdit', 'word')"
      ></m-icon>
      <m-icon
        v-if="item.Type === 2"
        class="pl"
        :icon="UtilGetIcon('view-MaterialEdit', 'document')"
      ></m-icon>
      <m-icon
        v-if="item.Type === 3"
        class="pl"
        :icon="UtilGetIcon('view-MaterialEdit', 'video')"
      ></m-icon>
      <m-icon
        v-if="item.Type === 4"
        :icon="UtilGetIcon('view-MaterialEdit', 'presentation')"
      ></m-icon>
    </template>

    <template slot="item.Name" slot-scope="{ item }">
      <span v-if="item.IsLesson && item.Name !== 'Урок'"
        >Урок {{ item.Num }}. {{ item.Name }}</span
      >
      <span v-else-if="item.IsLesson">Урок {{ item.Num }}</span>
      <span v-else>{{ item.Name }}</span>
    </template>

    <template slot="item.Status" slot-scope="{ item }">
      <div
        v-if="isEmployee && item.IsLesson && item.Status > 0"
        class="knowledge-base__status-point__wrapper"
      >
        <span
          class="knowledge-base__status-point"
          :class="'knowledge-base__status-point--' + item.Status"
        ></span>
        Пройдено <b>{{ item.Completed || 0 }}</b> из
        <b>{{ item.Total || 0 }}</b>
      </div>
      <div
        v-else-if="isEmployee && !item.IsLesson"
        class="knowledge-base__status-point__wrapper"
        style="text-align: center"
      >
        <span
          class="knowledge-base__status-point"
          :class="
            'knowledge-base__status-point--' +
            (item.Result ? 3 : item.Result === false ? 4 : 1)
          "
        ></span>
      </div>
    </template>

    <template slot="item.actions" slot-scope="{ item }">
      <menu-add-records
        v-if="item.IsLesson && !readonly"
        @select="addItem($event, item)"
        inLesson
      >
        <template slot="activator" slot-scope="{ on }">
          <v-btn v-on="on" text color="primary" icon>
            <m-icon icon="mdi-plus-circle"></m-icon>
          </v-btn>
        </template>
      </menu-add-records>
      <select-employees
        v-if="!item.IsLesson && (!readonly || isCurator)"
        :source-employees="employees.map((e) => e.Employee)"
        dialogTitle="Выбор сотрудников, у которых нужно обновить материал"
        button-save-text="Применить"
        @input="appointMaterial($event, item)"
      >
        <template #activator="{ on }">
          <v-btn v-on="on" icon title="Обновить материал у сотрудников">
            <m-icon icon="mdi-sync" size="20px"></m-icon>
          </v-btn>
        </template>
      </select-employees>
      <v-btn
        v-if="!readonly"
        icon
        color="error"
        @click.prevent.stop="deleteElement(item)"
        title="Удалить"
      >
        <m-icon icon="tool-delete"></m-icon>
      </v-btn>
    </template>
    <copy-records
      v-model="showCopy"
      @save="addNewLessons"
      dialogTitle="Выбор сотрудников, у которых нужно обновить материал"
      button-save-text="Применить"
    >
    </copy-records>
  </base-tree-list-view>
</template>
<script>
import KnowledgeBaseService from "@/services/KnowledgeBaseService";
import ListEditMixin from "@/mixins/ListEditMixin";
import BaseTreeListView from "../../../layouts/BaseTreeListView.vue";
import menuAddRecords from "./menuAddRecords.vue";
import SelectEmployees from "../../../components/organization/employees/SelectEmployees.vue";
import copyRecords from "./copyRecords.vue";
export default {
  name: "knowledgeBase-records",
  components: {
    BaseTreeListView,
    menuAddRecords,
    SelectEmployees,
    copyRecords,
  },
  mixins: { ListEditMixin },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isCurator: {
      type: Boolean,
      default: false,
    },
    pageEditName: {
      type: String,
      default: "",
    },
    isEmployee: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default: () => [],
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    courseType: {
      type: String,
      default: "all",
      validator: function (value) {
        return ["all", "base"].includes(value);
      },
    },
  },
  data() {
    return {
      showCopy: false,
      headers: [
        {
          text: "Учебный материал",
          value: "Name",
          sortable: false,
          cellClass: "td1",
        },
        { text: "Вид", value: "Type", sortable: false, cellClass: "td5" },
        {
          text: "Статус",
          value: "Status",
          align: "center",
          sortable: false,
          cellClass: "td6",
        },
      ],
      addMenu: [
        {
          key: "lesson",
          text: "Урок",
          icon: { name: "view-MaterialEdit", type: "lesson" },
        },
        {
          key: "video",
          text: "Видео",
          icon: { name: "view-MaterialEdit", type: "video" },
        },
        {
          key: "word",
          text: "Документ Word",
          icon: { name: "view-MaterialEdit", type: "word" },
        },
        {
          key: "presentation",
          text: "Презентация",
          icon: { name: "view-MaterialEdit", type: "presentation" },
        },
        {
          key: "doc",
          text: "Локальный документ",
          icon: { name: "view-MaterialEdit", type: "document" },
        },
        {
          key: "test",
          text: "Тест",
          icon: { name: "view-TestEdit" },
        },
        {
          key: "copy",
          text: "Копировать из курса",
          icon: { name: "CoursesCopy" },
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      if (!this.isEmployee) {
        const headersWhithoutStatus = this.headers.filter(
          (h) => h.value !== "Status"
        );
        headersWhithoutStatus.push({
          text: "",
          value: "",
          align: "center",
          sortable: false,
          cellClass: "td6",
        });
        return headersWhithoutStatus;
      }
      return this.headers;
    },
  },
  methods: {
    customAdd(key) {
      this.addItem(key);
    },
    addNewLessons(items) {
      this.$emit("copyNewLessons", items);
    },
    startCopy(lesson) {
      this.showCopy = true;
      console.log(lesson);
    },
    appointMaterial(employees, item) {
      KnowledgeBaseService.material.appointMaterial({
        EmployeeIds: employees.map((e) => e.Id),
        MaterialId: item.Type !== 0 ? item.Id : 0,
        TestId: item.Type === 0 ? item.Id : 0,
      });
    },
    customHandle(val) {
      if (this.isNested) return;
      if (val && val.IsLesson && this.readonly) return;

      if (val && val.IsLesson && !this.readonly) {
        this.$emit("editLesson", val.Id);
        return;
      }
      // todo: Говно код, исправить. SET_TRANSACTION_LIST_VIEW_DETAIL - Региструет функции из спичного предствления, текущий компонент не списачное представление!
      this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
        listViewUpdateItems: this.updateItem,
        listViewInit: this.$parent?.init,
      });

      if (val.Type === 0) {
        if (this.isEmployee) {
          this.$router.push({
            name: "KnowledgeBaseTestEmployeeEdit",
            params: {
              objectId: val.RecordId,
            },
          });
        } else {
          this.$router.push({
            name: "KnowledgeBaseTestEdit",
            params: {
              objectId: val.Id,
            },
          });
        }
        return;
      }

      if (this.isEmployee) {
        this.$router.push({
          name: "KnowledgeBaseMatarialEmployeeEdit",
          params: {
            objectId: val.RecordId,
          },
        });
      } else {
        this.$router.push({
          name: "KnowledgeBaseMatarialEdit",
          params: {
            objectId: val.Id,
          },
          query: {
            courseType: this.courseType,
          },
        });
      }
    },
    updateItem(item) {
      const i = this.value.findIndex(
        (e) => e.RecordId === (item.TestId || item.RecordId)
      );
      if (i >= 0) {
        if (this.value[i].Result !== item.Result) this.$emit("refresh");
        this.value[i].Result = item.Result;
      }
    },
    addItem(e, lesson) {
      if (this.type === "create") {
        this.$alert("Для добавления урока/учебного материала сохраните курс.");
        return;
      }
      switch (e) {
        case "lesson":
          this.addLesson();
          break;
        case "word":
          this.addMaterial(1, lesson);
          break;
        case "doc":
          this.addMaterial(2, lesson);
          break;
        case "video":
          this.addMaterial(3, lesson);
          break;
        case "presentation":
          this.addMaterial(4, lesson);
          break;
        case "test":
          this.addMaterial(0, lesson);
          break;
        case "copy":
          this.startCopy(lesson);
          break;
      }
    },
    addLesson() {
      this.$emit("addLesson");
    },
    addMaterial(type, lesson) {
      this.$emit("addMaterial", { type, lesson });
    },
    deleteElement(item) {
      if (item.IsLesson) this.$emit("deleteLesson", item.Id);
      else this.$emit("deleteMaterial", item.Id);
    },
  },
};
</script>

<style lang="scss">
#knowledge-base__records {
  .v-row-group__header {
    background-color: unset !important;
  }
  .td1 {
    width: 100%;
  }
  .td5 {
    width: 58px !important;
    max-width: 58px !important;
    min-width: 58px !important;
  }
  .td6 {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
  .pl {
    padding-left: 2px;
  }
}
</style>
